































import {
  computed, defineComponent, inject, PropType, ref,
} from '@nuxtjs/composition-api';
import type {Aggregation} from '~/modules/GraphQL/types';

import Checkbox from '~/components/Palmers/BaseComponents/Form/Checkbox.vue';

export default defineComponent({
  components: {
    Checkbox
  },
  data() {
    return {
      showMore: false
    }
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
    maxOptionCount: {
      type: Number,
      default() {
        return 5
      }
    }
  },
  setup(props) {
    const options = ref({
      less: [],
      more: []
    });

    props.filter.options.map((option, index) => {
      if (index < props.maxOptionCount) {
        options.value.less.push(option);
      } else {
        options.value.more.push(option);
      }
    })

    const {isFilterSelected} = inject('UseFiltersProvider');
    const selected = computed(() => ((id: string, optVal: string) => isFilterSelected(id, optVal)));
    return {selected, options};
  },
});
