



















import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      require: true
    },
    value: {
      type: String,
      require: true
    },
    checked: {
      type: Boolean
    }
  }
});
